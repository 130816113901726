import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {StorageService} from '../services/storage.service';
import {UserService} from '../services/user.service';

declare const ENV: any;

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    private env = ENV;

    constructor(private http: HttpClient, private storageService: StorageService, private userService: UserService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Adding serverHostURL to all APIs in Interceptor
        const serverHostURL = this.env.apiUrl;
        request = request.clone({
            url: serverHostURL + request.url
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.error === 'token_expired') {
                    this.storageService.deleteToken();
                    this.userService.logout()
                    window.location.href = '/';
                }
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                return throwError(error);
            })
        );
    }
}
