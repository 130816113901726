import {Injectable} from '@angular/core';
import {CookieService} from 'ng2-cookies';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _token: string;

  constructor(private cookieService: CookieService) {
  }

  get sessionToken(): string {
    if (!this._token) {
      this._token = this.cookieService.get('CSHOP_SESSION');
    }
    return this._token;
  }

  set sessionToken(token) {
    if (token === null) {
      this.cookieService.set('CSHOP_SESSION', token, -1, '/');
    } else {
      const date = new Date();
      date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
      this.cookieService.set('CSHOP_SESSION', token, date, '/');
    }
    this._token = token;
  }

  deleteToken() {
    this.cookieService.delete('CSHOP_SESSION');
  }
}
