import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS,
    HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {StorageService} from '../services/storage.service';
import {Router} from '@angular/router';


@Injectable()
export class GeneralInterceptor implements HttpInterceptor {

    constructor(private storageService: StorageService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const pid = this.storageService.sessionToken;

        if (pid && pid.length) {
            const interceptRequest = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + pid
                }
            });

            return next.handle(interceptRequest)
                .pipe(
                    catchError((response: any) => {
                        // tslint:disable-next-line: max-line-length
                        if (response instanceof HttpErrorResponse) {
                            if (response.status !== 400 || (response.error && response.error.data && response.error.data.stopper)) {
                                this.router.navigateByUrl('pages/auth/login');
                            }
                            return throwError(response);
                        }
                    })
                );
        } else {
            const interceptRequest = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                }
            });

            return next.handle(interceptRequest)
                .pipe(
                    catchError((response: any) => {
                        // tslint:disable-next-line: max-line-length
                        if (response instanceof HttpErrorResponse) {
                            if (response.status === 400 || (response.error && response.error.data && response.error.data.stopper)) {
                                this.router.navigateByUrl('pages/auth/login');
                            }
                            return throwError(response);
                        }
                    })
                );
        }
    }
}

export const generalInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: GeneralInterceptor,
    multi: true
};
